import React, { useEffect, useState, useContext } from 'react';
import DataStoreContext from 'dataStoreContext';
import { useHistory } from "react-router-dom";
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import TitleBar from 'js/components/titleBar.js';

import Api from "services/api.js";
import { Formik } from "formik";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import ProgressButton from 'js/components/progressButton';

import 'styles/loginForm.scss';
import 'styles/registrationView.scss';
import 'styles/common.scss';
import 'styles/scanOutletView.scss';
import HelperService from '../../services/helpers';

export default function SetPasswordView() {
    const { setInfoModal} = useContext(DataStoreContext);
    const { t, i18n } = useTranslation('views');
    const [lang, setLang] = useState('sv');
    let history = useHistory();

    // State to hold initial values
    const [initialValues, setInitialValues] = useState({
        email: '',
        password: '',
        showPassword: false,
        firstName: '',
        lastName: '',
        providerNames: '',
        inviter: '',
    });

    useEffect(() => {            
        const userName = HelperService.getQueryParameter(window.location.href, "userName");
        const firstName = HelperService.getQueryParameter(window.location.href, "firstName");
        const lastName = HelperService.getQueryParameter(window.location.href, "lastName");
        const lang = HelperService.getQueryParameter(window.location.href, "preferredLanguage");        
        let inviter = HelperService.getQueryParameter(window.location.href, "inviter");        
        let providerNames = HelperService.getQueryParameter(window.location.href, "providerNames"); 

        if (lang) {
            setLang(lang);
        }
        if (inviter) inviter = inviter.replaceAll('+', ' ');
        if (providerNames) providerNames = providerNames.replaceAll('+', ' ');

        setInitialValues(prevValues => ({
            ...prevValues,
            email: userName || '', // Assuming email is the username
            firstName: firstName || '',
            lastName: lastName || '',
            inviter: inviter || '',
            providerNames: providerNames || '',
        }));
    }, []);

    useEffect(() => {                    
        if (lang) {            
            setTimeout(() => {
                i18n.changeLanguage(lang);
            }, 100); // Delay by 2 seconds
        }
    }, [lang, i18n]);
    

    const handleCancelClick = () => {
        history.push("/");
    };

    const IS_ADMIN_INVITE = (values) => (values.providerNames && values.providerNames.length);

    const handleSubmit = async (values, { setSubmitting, setErrors }) => {
        try {
            await Api.confirmInvitedUser({userName: values.email, password: values.password});
            setSubmitting(false);
            if (IS_ADMIN_INVITE(values)) window.location.href = 'https://businessmanager.epspot.com/user/login';
            else history.push("/");
        } catch (error) {
             setInfoModal([error.message]);
        }
            setSubmitting(false);
    };

    const isDisabled = (values, errors) => {
        return (
            !values.email || // Ensure email is provided
            !!errors.email || // Ensure no email errors (convert to boolean)
            !!errors.password || // Ensure no password errors (convert to boolean)
            !values.password || // Ensure password is not empty
            values.password.length < 8 // Ensure password is at least 8 characters
        );
    };

    const renderInviter = (v) => {
        if (v.inviter) return (
        <span>
            {t("setPwForm.account_invite_BM_2")} {v.inviter}.
        </span>
        )
        return (<span>.</span>)
    }

    const renderProviderNames = (v) => {
        if (v.providerNames) return (
        <span>
            {t("setPwForm.account_invite_BM_4")} {v.providerNames}.
        </span>
        )
        return null;
    }

    return (
        <div className="sliding-view">
            <div className="bg"></div>
            <div className="cover"></div>
            <TitleBar icon={faChevronLeft} clickCB={handleCancelClick} title={t("setPwForm.title")}/>
            
            <div className="login-form user-invite" show aria-labelledby="contained-modal-title-vcenter">
                <div className="content">
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize={true}  // Allow reinitialization when values change
                        onSubmit={handleSubmit}
                    >
                        {({ values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, status, setFieldValue, setFieldTouched }) => (
                            <>
                            {
                                IS_ADMIN_INVITE(values) ?
                                <Form style={{ marginTop: '30px' }} onSubmit={handleSubmit}>
                                <Form.Group>
                                    <h2>{t("setPwForm.welcome_to", {firstName: values.firstName})}</h2>
                                    <p>{t("setPwForm.account_invite_BM_1")} {renderInviter(values)}</p>
                                    <p>{renderProviderNames(values)}</p>
                                    <p>{t("setPwForm.account_invite_BM_3")} <span className="user-name">{values.email}</span></p>
                                    
                                    
                                    <p>{t("setPwForm.account_invite_2")}</p>
                                    <p>{t("setPwForm.regret")}</p>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>{t("setPwForm.new_password")}</Form.Label>
                                    <InputGroup>
                                        <Form.Control 
                                            size="lg"
                                            id="password"
                                            name="password"
                                            type={values.showPassword ? "text" : "password"}
                                            placeholder={t("setPwForm.pw_placeholder")}
                                            value={values.password}
                                            onChange={(e) => {
                                                handleChange(e);
                                                setFieldTouched("password", true, false); // Ensure field is marked as touched
                                            }}
                                            onBlur={handleBlur}
                                            className={errors.password && touched.password && "error"}
                                        />
                                        <Form.Text className="showPasswordIcon" name="showPassword"> 
                                            {values.showPassword ? (
                                                <FontAwesomeIcon onClick={() => setFieldValue("showPassword", false)} icon={faEyeSlash} />
                                            ) : (
                                                <FontAwesomeIcon onClick={() => setFieldValue("showPassword", true)} icon={faEye} />
                                            )}
                                        </Form.Text>
                                        {!!status && (<Form.Text className="input-feedback"> {status} </Form.Text>)}     
                                    </InputGroup>
                                    {!!status && (<Form.Text className="input-feedback"> {status} </Form.Text>)}
                                    {errors.password && touched.password && (
                                        <Form.Text className="input-feedback password"> {errors.password} </Form.Text>
                                    )}
                                </Form.Group>

                                <div className="confirm-cancel-button-row">
                                    <ProgressButton 
                                        disabled={isDisabled(values, errors)} 
                                        className="login-button" 
                                        text={t("setPwForm.set_pw")} 
                                        loading={isSubmitting} 
                                        clickCB={handleSubmit}
                                    />
                                    <Button className="cancel-button" type="cancel" onClick={handleCancelClick}>
                                        {t("setPwForm.cancel")}
                                    </Button>    
                                </div>
                                <Form.Text style={{ textAlign: 'center', marginTop: '-30px', marginBottom: '10px', color: '#555555' }}>
                                    {t("setPwForm.activation_info_BM")}
                                </Form.Text>    

                                <div className="small-text">
                                    {t("setPwForm.by_activating")}&nbsp;
                                    <span className="link" onClick={()=>window.open('https://www.epspot.com/tos')}>
                                        {t('aboutView.termsOfUse')}
                                    </span>
                                    <span>&nbsp;{t("setPwForm.and")}&nbsp;</span>
                                    <span className="link" onClick={()=>window.open('https://www.epspot.com/cookie-policy/')}>
                                    {t('aboutView.privacyPolicy')}.
                                    </span>
                                    </div>
                                    
                            </Form>
                            :
                            <Form style={{ marginTop: '30px' }} onSubmit={handleSubmit}>
                                <Form.Group>
                                    <h2>{t("setPwForm.welcome_to", {firstName: values.firstName})}</h2>
                                    <p>{t("setPwForm.account_invite_1")}</p>
                                    <p className="user-name">{values.email}</p>
                                    <p>{t("setPwForm.account_invite_2")}</p>
                                    <p>{t("setPwForm.regret")}</p>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>{t("setPwForm.new_password")}</Form.Label>
                                    <InputGroup>
                                        <Form.Control 
                                            size="lg"
                                            id="password"
                                            name="password"
                                            type={values.showPassword ? "text" : "password"}
                                            placeholder={t("setPwForm.pw_placeholder")}
                                            value={values.password}
                                            onChange={(e) => {
                                                handleChange(e);
                                                setFieldTouched("password", true, false); // Ensure field is marked as touched
                                            }}
                                            onBlur={handleBlur}
                                            className={errors.password && touched.password && "error"}
                                        />
                                        <Form.Text className="showPasswordIcon" name="showPassword"> 
                                            {values.showPassword ? (
                                                <FontAwesomeIcon onClick={() => setFieldValue("showPassword", false)} icon={faEyeSlash} />
                                            ) : (
                                                <FontAwesomeIcon onClick={() => setFieldValue("showPassword", true)} icon={faEye} />
                                            )}
                                        </Form.Text>
                                        {!!status && (<Form.Text className="input-feedback"> {status} </Form.Text>)}     
                                    </InputGroup>
                                    {!!status && (<Form.Text className="input-feedback"> {status} </Form.Text>)}
                                    {errors.password && touched.password && (
                                        <Form.Text className="input-feedback password"> {errors.password} </Form.Text>
                                    )}
                                </Form.Group>

                                <div className="confirm-cancel-button-row">
                                    <ProgressButton 
                                        disabled={isDisabled(values, errors)} 
                                        className="login-button" 
                                        text={t("setPwForm.set_pw")} 
                                        loading={isSubmitting} 
                                        clickCB={handleSubmit}
                                    />
                                    <Button className="cancel-button" type="cancel" onClick={handleCancelClick}>
                                        {t("setPwForm.cancel")}
                                    </Button>    
                                </div>
                                <Form.Text style={{ textAlign: 'center', marginTop: '-30px', marginBottom: '10px', color: '#555555' }}>
                                    {t("setPwForm.activation_info")}
                                </Form.Text>    

                                <div className="small-text">
                                    {t("setPwForm.by_activating")}&nbsp;
                                    <span className="link" onClick={()=>window.open('https://www.epspot.com/tos')}>
                                        {t('aboutView.termsOfUse')}
                                    </span>
                                    <span>&nbsp;{t("setPwForm.and")}&nbsp;</span>
                                    <span className="link" onClick={()=>window.open('https://www.epspot.com/cookie-policy/')}>
                                    {t('aboutView.privacyPolicy')}.
                                    </span>
                                    </div>
                                    
                            </Form>
                            }
                            </>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}
