import reduce from 'lodash/reduce';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import 'moment-duration-format';
import React from 'react';



const HelperService = {
    /* Construct a support mail link on form 'mailto:support@epspot.com?subject=App Support!&amp;body=%0A%0A-----------------------%0ALämna allt nedanför orört%0AUSER:Pelle%0ATIME=2'
    options is the data to insert in the support mail: {outlet, user, activeSessions}
    */
    buildSupportMailLink: function (email, subject, options) {
        let link = `mailto:${email}?subject=`;
        link += encodeURIComponent(subject);
        link += '&body=';

        link += encodeURIComponent('\n\nPlease do not modify below this line -----------------------\n');

        if (options && options.outlet) {
            link += encodeURIComponent(`SCANNED OUTLET\n`);
            link += encodeURIComponent(`Outlet Id: ${options.outlet.id}\n`);
            link += encodeURIComponent(`Name: ${options.outlet.name}\n`);
            if (options.outlet.basicProvider) link += encodeURIComponent(`Provider: ${options.outlet.basicProvider.name}\n`);
            if (options.outlet.basicLocation) link += encodeURIComponent(`Location: ${options.outlet.basicLocation.name}\n`);
            if (options.outlet.outletStatus) {
                link += encodeURIComponent(`Outlet Status: ${options.outlet.outletStatus.status}\n`);
                link += encodeURIComponent(`Plug Inserted: ${options.outlet.outletStatus.plugInserted}\n`);
            }
        }
        link += encodeURIComponent(`\nCURRENT USER\n`);
        if (options && options.user) {
            link += encodeURIComponent(`User: ${options.user.userName}\n`);
            if (options.user.epspotAccount_SEK) link += encodeURIComponent(`Balance: ${options.user.epspotAccount_SEK.balance} kr\n`);
        }
        else {
            link += encodeURIComponent(`User: Not logged in\n`);
        }   
        
        if (options && options.activeSessions) {
            link += encodeURIComponent(`\nONGOING SESSIONS\n`);
            for (const session of options.activeSessions) {
                link += encodeURIComponent(`Session: ${session.id}\n`);
            }
        }
        
        return link;

    },

    getQueryParameter: function (url, param) {
        
        var pathParts = url.split('/');

        var lastPathParts = pathParts[pathParts.length - 1].split('?');
        var search = {};
        if (lastPathParts.length > 1) {
            var searchParts = lastPathParts[1].split("&");
            search = searchParts.reduce(function (obj, i) {
                var t = i.split("=");
                obj[t[0]] = t[1];
                return obj;
            }, {});
        }
        if (search.hasOwnProperty(param)) {
            return search[param];
        }
        return null;
    },

    getSessionRef: function (url) {

        var parsedUrl;
        if (url) parsedUrl = new URL(url);
        else parsedUrl = new URL(window.location.href);
        return parsedUrl.searchParams.get("sessionReferenceId");
    },

    //https://power.epspot.com/oid/000-000-xxx
    //https://epspot.com/oid/000-000-xxx
    //epspot://oid/000-000-xxx

    getPathOutletId: function (url) {
        var parsedUrl 
        if (url) parsedUrl = new URL(url);
        else parsedUrl = new URL(window.location.href);
        //pathname = /oid/000-000-678 for new url-format /000-000-678 for old format
        let id = parsedUrl.pathname.split("/").pop();

        var regexp = new RegExp("^([0-9]{3}-[0-9]{3}-[0-9]{3})$");
        if (regexp.test(id)) return id;
        return null;
        /*var path = parsedUrl.pathname.split('/');
        let oidPos = path.indexOf('oid'); 
        if (oidPos >= 0 && path.length > oidPos) {
            return path[oidPos+1];
        }
        return null;
        */
    },

    isOutletInserted: function(outlet) {
        if ( outlet && outlet.latestRequest && outlet.latestRequest.hasOwnProperty("msg") &&
            outlet.latestRequest.msg.hasOwnProperty("info") &&
            outlet.latestRequest.msg.info.inserted ) {
                return true;
            }
        return false;    
    },

    isMySession: function(sessionRefId) {
        try {
            let storedRef = window.localStorage.getItem('sessionReferenceId');
            return (storedRef && storedRef === sessionRefId);
        } catch (error) {
            console.log(error);
            return false;
        }
    },

    // From the list of paymentCards, return the one with card.metadata.defaultCard == 'true'.
    getPrimaryPaymentCard: function(paymentCards) {
        if (paymentCards && paymentCards.length > 0) {
            for (let i = 0; i < paymentCards.length; i++) {
                if (paymentCards[i].metadata && paymentCards[i].metadata.defaultCard && paymentCards[i].metadata.defaultCard === 'true') {
                    return paymentCards[i];
                }            
            }            
        }
        return null;
    },

    // Get the sum of all refunds for a given receipt
    getRefundAmount: function(receipt) {
        if (!(receipt && receipt.receiptMetadata && receipt.receiptMetadata.refunds)) return 0;
        let refundAmount = 0;
        for (let i = 0; i < receipt.receiptMetadata.refunds.length; i++) {
            const refund = receipt.receiptMetadata.refunds[i];
            refundAmount += refund.amount;
        }  
        return refundAmount;
    },

    /* Calculate total cost in integer form for a given time (in seconds) with a given tariff
    sliderData = {time:0, kWh: 0} (time in seconds)
    */
   getTotalCost : function(sliderData, tariff, loggedInMode) {
        let openingFee = (loggedInMode) ? tariff.openingFee : tariff.singlePaymentOpeningFee;
        let currentKWhCost = (tariff.feeKwh) * sliderData.kWh;
        let currentHourCost = sliderData.time * (tariff.feeHour / 3600.0);
        return 100 * (currentHourCost + currentKWhCost + openingFee);
    },

    getTariffRenderElement : function(loggedInView, outlet) {
        if (! outlet.tariff) return null;
        let t = outlet.tariff;

        if (t.type === 'RUNNING') { //Löpande laddning
            if (!t.feeKwh) t.feeKwh = 0;
            if (!t.feeHour) t.feeHour = 0;
            
            let openingFee = 0;
            if (loggedInView && t.openingFee) openingFee = t.openingFee;
            else if (!loggedInView && t.singlePaymentOpeningFee) openingFee = t.singlePaymentOpeningFee;
            
            if (t.feeKwh === 0 && openingFee === 0 && t.feeHour === 0) return (<span>ingen avgift</span>);
            return (
                <>
                    {(t.feeKwh > 0) ? <span>{parseFloat(t.feeKwh.toFixed(2))} kr/kWh</span> : null}
                    {(t.feeKwh > 0 && t.feeHour && t.feeHour> 0) ? ' + ' : null} 

                    {(t.feeHour > 0) ? <span>{parseFloat(t.feeHour.toFixed(2))}  kr/tim</span> : null}
                    
                    {((t.feeKwh > 0) || (t.feeHour > 0)) && (openingFee > 0) ? ' + ' : null} 

                    {(openingFee > 0) ? <span>{parseFloat(openingFee.toFixed(2))}  kr i öppning</span> : null}
                </>
            )
        }
        else if (t.type === 'SLOT' && t.slotPeriod === 24) { // Dygnsladdning
            if (!t.feeTimeslot) t.feeTimeslot = 0;
            let openingFee = 0;
            if (loggedInView && t.openingFee) openingFee = t.openingFee;
            else if (!loggedInView && t.singlePaymentOpeningFee) openingFee = t.singlePaymentOpeningFee;
            
            return (
                <>
                    <span>Periodladd, {parseFloat(t.feeTimeslot.toFixed(0))} kr/dygn</span>
                    {(openingFee > 0) ? <span> + {parseFloat(openingFee.toFixed(2))}  kr i öppning</span> : null} 
                    {(t.slotEndHour) ? <span> (periodslut {t.slotEndHour}:00)</span> : null} 
                </>
            )
        }
        else if (t.type === 'SLOT' && t.slotPeriod !== 24) { // Timperiodladdning
            if (!t.feeTimeslot) t.feeTimeslot = 0;
            let openingFee = 0;
            if (loggedInView && t.openingFee) openingFee = t.openingFee;
            else if (!loggedInView && t.singlePaymentOpeningFee) openingFee = t.singlePaymentOpeningFee;
            
            return (
                <>
                    {(t.slotPeriod === 1) ? <span>Periodladd, {parseFloat(t.feeTimeslot.toFixed(0))} kr/timme</span> : null} 
                    {(t.slotPeriod > 1) ? <span>Periodladd, {parseFloat(t.feeTimeslot.toFixed(0))} kr / {t.slotPeriod} timmar</span> : null} 
                    {(openingFee > 0) ? <span> + {parseFloat(openingFee.toFixed(2))}  kr i öppning</span> : null} 
                </>
            )
        }
        else return null; //something wrong
    },

    /* Check if a tariff if free
    */
   isZeroTariff : function(tariff) {
       if (!tariff) return false;
        if ((tariff.openingFee && tariff.openingFee > 0) ||
            (tariff.singlePaymentOpeningFee && tariff.singlePaymentOpeningFee > 0) ||
            (tariff.feeHour && tariff.feeHour > 0) ||
            (tariff.feeKwh && tariff.feeKwh > 0) ||
            (tariff.feeTimeslot && tariff.feeTimeslot > 0) ||
            (tariff.feeTimeslotNoPower && tariff.feeTimeslotNoPower > 0) ){
            return false;
        }
        return true;
    },

    /* Get the type of unning tariff, one of:
    KWH_TARIFF, HOUR_TARIFF, COMBO_TARIFF, NO_RUNNING_TARIFF
    */
    getTariffFeeType : function(tariff) {
        if (!tariff) return 'UNKNOWN';
        if (tariff.feeKwh && tariff.feeKwh > 0 && (!tariff.feeHour || !tariff.feeHour > 0)) return 'KWH_TARIFF';
        if (tariff.feeHour && tariff.feeHour > 0 && (!tariff.feeKwh || !tariff.feeKwh > 0)) return 'HOUR_TARIFF';
        if (tariff.feeHour && tariff.feeHour > 0 && tariff.feeKwh && tariff.feeKwh > 0) return 'COMBO_TARIFF';
        if (tariff.feeHour === 0 && tariff.feeKwh === 0) return 'FREE_TARIFF';
        return 'NO_RUNNING_TARIFF';
    },

    /* Get the maximum charge for a given outlet in ampere
    */
    getMaxCharge_Amps: function (outlet) {
        let maxChargeAmps = 0;

        // maxThroughput, throughputLimit are legacy values and can be removed once we have fully migrated
        // to new model.
        if (outlet.maxThroughput) {
            maxChargeAmps = Math.min(outlet.maxThroughput.p1, outlet.maxThroughput.p2, outlet.maxThroughput.p3);
        }
        if (outlet.throughputLimit) {
            maxChargeAmps = Math.min(maxChargeAmps, outlet.throughputLimit.p1, outlet.throughputLimit.p2, outlet.throughputLimit.p3);
        }

        if (outlet.maxCapacity) {
            maxChargeAmps = Math.min(outlet.maxCapacity.p1, outlet.maxCapacity.p2, outlet.maxCapacity.p3);
        }
        if (outlet.cappedCapacity) {
            maxChargeAmps = Math.min(maxChargeAmps, outlet.cappedCapacity.p1, outlet.cappedCapacity.p2, outlet.cappedCapacity.p3);
        }


        return maxChargeAmps;
    },

    /* Get the maximum charge amount for a given outlet in kW
    */
    getMaxCharge_kW : function(outlet) {
        let maxChargeAmps = HelperService.getMaxCharge_Amps(outlet);
        return maxChargeAmps * 230.0 / 1000.0;
    },
    
    /* Check if outlet is configured for 2023 februari car pool demo
    */
    isCarPoolDemo : function(outlet){
        return outlet.tariff &&
        outlet.tariff.paymentMethods &&
        outlet.tariff.paymentMethods.length === 1 && 
        outlet.tariff.paymentMethods.includes('CSV_REGISTERED_PAYMENT') &&
        outlet.tariff.providerCollectRequiredAuth && 
        outlet.tariff.providerCollectRequiredAuth.includes('LICENSE_PLATE')
    },

    // From the list of paymentCards, return the one with card.metadata.defaultCard == 'true'.
    getPaymentCard: function(paymentCards, cardId) {
        if (paymentCards && paymentCards.length > 0) {
            for (let i = 0; i < paymentCards.length; i++) {
                if (paymentCards[i].id === cardId) {
                    return paymentCards[i];
                }            
            }
        }
        return null;
    },

    /* Converts an array of locations into GeoJSON format */
    convertToGeoJSON: function(nearbyLocations) {
        let points = nearbyLocations.map(loc => ({
            type: "Feature",
            properties: { cluster: false, location: loc },
            //properties: { cluster: false, category: 'hej' },
            geometry: {
              type: "Point",
              coordinates: [
                parseFloat(loc.longitude),
                parseFloat(loc.latitude)
              ]
            }
        }));
        return points;
    },

    /* An Unregistered Active Session is a session for an anonymous/not-logged-in user. These sessions 
       we keep track of in local storage so we can reopen them if app is relaunched */
    getUnregisteredActiveSessions: function () {
        let uas = window.localStorage.getItem('UNREGISTERED_ACTIVE_V2SESSIONS');
        if (uas) return JSON.parse(uas);
        return [];
    },

    /* UNREGISTERED_ACTIVE_V2SESSIONS contains {sessionId, providerId} objects
    */
    addSessionToUnregisteredActiveSessions: function (sessionData) {
        let uas = window.localStorage.getItem('UNREGISTERED_ACTIVE_V2SESSIONS');
        if (uas) {
            uas = JSON.parse(uas);
            const found = uas.find(s => s.sessionId === sessionData.sessionId);
            if (!found) uas.push(sessionData);
        }
        else {
            uas = [sessionData];
        }
        window.localStorage.setItem("UNREGISTERED_ACTIVE_V2SESSIONS", JSON.stringify(uas));
    },

    removeSessionFromUnregisteredActiveSessions: function (sessionId) {
        let uas = window.localStorage.getItem('UNREGISTERED_ACTIVE_V2SESSIONS');
        if (uas) {
            uas = JSON.parse(uas);
            uas = uas.filter(s => s.sessionId !== sessionId);
            if (uas.length > 0) window.localStorage.setItem("UNREGISTERED_ACTIVE_V2SESSIONS", JSON.stringify(uas));
        }
        
        if (!uas || uas.length === 0) {
            window.localStorage.removeItem("UNREGISTERED_ACTIVE_V2SESSIONS");
        }  
    },

    /* An Unregistered Active Session is a session for an anonymous/not-logged-in user. These sessions 
       we keep track of in local storage so we can reopen them if app is relaunched */
    getUnregisteredCompletedSessions: function () {
        let ucs = window.localStorage.getItem('UNREGISTERED_COMPLETED_SESSIONS');
        if (ucs) return JSON.parse(ucs);
        return [];
    },
    addSessionToUnregisteredCompletedSessions: function (session) {
        let ucs = window.localStorage.getItem('UNREGISTERED_COMPLETED_SESSIONS');
        if (ucs) {
            ucs = JSON.parse(ucs);
            ucs.push(session);
        }
        else {
            ucs = [session];
        }
        ucs = HelperService.sortCompletedSessions(ucs);
        if (ucs.length > 10) ucs.length = 10;
        //console.log('ADDING TO UNREG COMPLETED');
        //console.log(JSON.stringify(ucs));
        window.localStorage.setItem('UNREGISTERED_COMPLETED_SESSIONS', JSON.stringify(ucs));
    },

    /* Since we have changed local receipt storage from entire receipt object 
     * to IDs only we cater for both right now to not break for old users
     */
    getUnregisteredPaymentReceiptIds: function () {
        let upr = window.localStorage.getItem('UNREGISTERED_PAYMENT_RECEIPTS');
        if (upr) {
            upr = JSON.parse(upr);
            let ids = [];
            for (let i = 0; i < upr.length; i++) {
                let item = upr[i];
                if (typeof item === 'object') ids.push(item.id);
                else ids.push(item);
            }      
            return ids;
        }
        return [];
    },

    /* PaymentReceipts for an unregistered user are stored locally on the device
     * (10 latest) in an id array.
    */
    addReceiptToUnregisteredPaymentReceipts: function (paymentReceiptId) {
        let upr = window.localStorage.getItem('UNREGISTERED_PAYMENT_RECEIPTS');
        if (upr && upr.length > 0) {
            upr = JSON.parse(upr);
            upr.unshift(paymentReceiptId);
        }
        else {
            upr = [paymentReceiptId];
        }
        //upr = HelperService.sortPaymentReceipts(upr);
        if (upr.length > 10) upr.length = 10;
        window.localStorage.setItem('UNREGISTERED_PAYMENT_RECEIPTS', JSON.stringify(upr));
    },

    getDevOptionsKey: function (key) {
        var userSettings = window.localStorage.getItem('userSettings');
        if (userSettings) {
            userSettings = JSON.parse(userSettings);
            if (userSettings && userSettings.devOptions && userSettings.devOptions[key]) {
                return userSettings.devOptions[key];      
            }
        }
        return null;
    },

    

    sortPaymentReceipts: function (receipts) {
        return receipts.sort((a,b)=>new Date(b.createdOn) - new Date(a.createdOn));
    },

    sortCompletedSessions: function (sessions) {
        let properSessions = sessions.filter((session) => session.startTime !== null && session.duration > 0);
        return properSessions.sort((a,b)=>new Date(b.startTime) - new Date(a.startTime));
    },

    /**
     * Returns all props from modified which are different from original.
     * First level diff only.
     * Example ({firstName: 'ola', lastName:'ason'}, {firstName: 'ola', lastName:'bson'}) => {lastName:'bson'}
     * 
     */
    getModifiedProps: function (original, modified) {
        let delta = reduce(original, function(result, value, key) {
            return isEqual(value, modified[key]) ?
                result : result.concat(key);
        }, []);
        let modifiedProps = {};
        for (let i = 0; i < delta.length; i++) {
            modifiedProps[delta[i]] = modified[delta[i]];
        }
        return modifiedProps;
    },

    getOS: function() {
        var userAgent = window.navigator.userAgent,
            platform = window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null;
      
        if (macosPlatforms.indexOf(platform) !== -1) {
          os = 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
          os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
          os = 'Windows';
        } else if (/Android/.test(userAgent)) {
          os = 'Android';
        } else if (!os && /Linux/.test(platform)) {
          os = 'Linux';
        }
        return os;
    },

    /* Check if two idPaths (fully qualified ids) are equal, eg. 
       idPath = ["V2Provider", "1d9ca81e-37c1-4309-8eb8-e7a351c13862", "V2Plant", "203627f0-0a30-4c2b-af5c-e9d7e9d6fe77", "V2Location", "b6846a16-11fc-49e2-a902-1b6ffbb64d3e"]
    */
    idPathsAreEqual: function(idPath1, idPath2) {
        if (!idPath1 && !idPath2) return true; // both null
        if (!idPath1 || !idPath2) return false; // one is null
        if (idPath1.length !== idPath2.length) return false;
        for (let i = 0; i < idPath1.length; i++) {
            if (idPath1[i].trim() !== idPath2[i].trim()) return false;
        }
        return true;
    },


    /*  key: 'HEATER_DATA'
    data: {startHour: x, startMinute: y} 
    */
    writeToLocalStorage: function(key, data) {
        try {
            let storageData = window.localStorage.getItem(key);
            if (storageData)
                storageData = JSON.parse(storageData);
            else
                storageData = {};

            let propNames = Object.keys(data);
            for (let i = 0; i < propNames.length; i++) {
                storageData[propNames[i]] = data[propNames[i]];

            }
            window.localStorage.setItem(key, JSON.stringify(storageData));
        } catch (error) {
            console.log(error);
        }
    },

    sleep : async function(seconds) {
        return new Promise(resolve => setTimeout(resolve, 1000*seconds));
    },

    // Calculate max initial debit so make sure user has high enough balance to start session
    // Calculation gives same value as equivalent calculation in UserService
    calculateMaxInitialDebit : async function(outlet, tariff) {
        let maxInitialDebit = 0;

        // Calculate what one minute of power would cost
        if (tariff.feeKwh > 0) {
            const feeWm = ((tariff.feeKwh * 100) / 60) / 1000; // Convert to minor units, get kw cost per minute, convert to w cost per minute
            const voltage = outlet.voltage;
            const amps = outlet.maxThroughput.p1;
            maxInitialDebit += ((voltage * amps) * feeWm);
        }

        // Calculate hourly price per minute
        if (tariff.feeHour > 0) {
            maxInitialDebit += (tariff.feeHour * 100) / 60;
        }

        // Add opening fee if available
        if (tariff.openingFee > 0) {
            maxInitialDebit += tariff.openingFee * 100;
        }
        return Math.round(maxInitialDebit);
    },


    getPhaseAmpValue : function(moduleRequest, phaseMapping, phase) {
        let phaseAmps = [0, 0, 0];
        let mappedPhases = [0, 0, 0];
        if (moduleRequest.info) phaseAmps[0] = moduleRequest.info.amps;
        if (moduleRequest.infoPhase1) phaseAmps[1] = moduleRequest.infoPhase1.amps;
        if (moduleRequest.infoPhase2) phaseAmps[2] = moduleRequest.infoPhase2.amps;

        if (phaseMapping.outletPhase1) mappedPhases[phaseMapping.outletPhase1-1] = phaseAmps[0];
        if (phaseMapping.outletPhase2) mappedPhases[phaseMapping.outletPhase2-1] = phaseAmps[1];
        if (phaseMapping.outletPhase3) mappedPhases[phaseMapping.outletPhase3-1] = phaseAmps[2];

        if (phase === 'L1') return mappedPhases[0] / 1000;
        if (phase === 'L2') return mappedPhases[1] / 1000;
        if (phase === 'L3') return mappedPhases[2] / 1000;
        return 0;
        
    },
    
    getCurrentPower : function(moduleRequest) {
        let totalPower = 0;
        if (moduleRequest.info && moduleRequest.info.power) totalPower += moduleRequest.info.power;
        if (moduleRequest.infoPhase2 && moduleRequest.infoPhase2.power) totalPower += moduleRequest.infoPhase2.power;
        if (moduleRequest.infoPhase3 && moduleRequest.infoPhase3.power) totalPower += moduleRequest.infoPhase3.power;
        return totalPower;
    }
};

export const FormatService = {


    // return time on 01:05:58 format
    formatSecondsCompact: function (value) {
       return moment.duration(value, 'seconds').format('HH:mm:ss', { trim: false });
    },

    formatCurrency: function (value, decimalPlaces, currency) {
        var nominalValue = value / Math.pow(10, decimalPlaces);
        nominalValue = nominalValue.toFixed(2);
        if (currency.toUpperCase() === 'SEK') {
            return nominalValue + ' kr';
        }
        return nominalValue;

    },

    formatMonth: function (monthNumber, months) {
        switch (monthNumber) {
            case 1: return months.jan
            case 2: return months.feb
            case 3: return months.mar
            case 4: return months.apr
            case 5: return months.may
            case 6: return months.jun
            case 7: return months.jul
            case 8: return months.aug
            case 9: return months.sep
            case 10: return months.oct
            case 11: return months.nov
            case 12: return months.dec
            default: return monthNumber;
        }
    },

    

    getCurrencyValue: function (value, decimalPlaces) {
        var nominalValue = value / Math.pow(10, decimalPlaces);
        return nominalValue.toFixed(2);
    },

    getCurrencyUnit: function (currency) {
        if (currency.toUpperCase() === 'SEK') {
            return 'kr';
        }
        return '';
    },

    roundTimeToNextQuarter: function (time) {
        var minutes = time.minutes();
        let remainder = minutes % 15;
        if (remainder > 0) {
            return time.minutes(minutes + 15-remainder);
        }
        return time;
    },

    

    

};

export const Session = {


    finalStatus: function (value) {
        switch (value) {
            case -1: return 'FINAL_STATUS_UNKNOWN'; 
            case 0: return 'FINAL_STATUS_SERVER_ERROR'; 
            case 1: return 'FINAL_STATUS_ENDED_BY_LOW_BALANCE'; 
            case 2: return 'FINAL_STATUS_ENDED_BY_PROVIDER'; 
            case 3: return 'FINAL_STATUS_ENDED_BY_ADMIN'; 
            case 4: return 'FINAL_STATUS_ENDED_BY_USER'; 
            case 5: return 'FINAL_STATUS_ENDED_NORMALLY'; 
            case 6: return 'FINAL_STATUS_ENDED_BY_FRAUD_SUSPECTION'; 
            case 7: return 'FINAL_STATUS_ENDED_CONNECTOR_REMOVED'; 
            case 8: return 'FINAL_STATUS_ENDED_TIME_MET'; 
            case 9: return 'FINAL_STATUS_ENDED_KWH_MET'; 
            case 10: return 'FINAL_STATUS_ENDED_BY_OUTLET'; 
            case 11: return 'FINAL_STATUS_ENDED_BY_BAD_MEASURE'; 
            case 12: return 'FINAL_STATUS_TO_LOW_POWER'; 
            case 13: return 'FINAL_STATUS_EVSE_DIOD_ERROR'; 
            case 14: return 'FINAL_STATUS_EVSE_VENT_REQUIRED_ERROR'; 
            case 15: return 'FINAL_STATUS_POWER_FAILURE'; 
            case 16: return 'FINAL_STATUS_MIN_LOAD_CONDITION_MET'; 
            case 17: return 'FINAL_STATUS_CONTACT_LOST'; 
            case 18: return 'FINAL_STATUS_ENDED_CONNECTOR_REMOVED_WITH_BIG_LOAD'; 
            case 19: return 'FINAL_STATUS_EVSE_ENDED_BY_VEHICLE'; 
            case 20: return 'FINAL_STATUS_EVSE_DC_LEAK_DETECTION'; 
            case 21: return 'FINAL_STATUS_EVSE_EMERGENCY_STOP'; 
            default: return 'unsupported state';
          }
    },
    finalStatusMessage: function (value) {
        switch (value) { 
            case 4: return 'Du valde att avsluta sessionen i förtid.';

            case 5: 
            case 8:  
            case 9: return 'Tack för din laddning och välkommen åter!';

            case 19:  
            case 7: 
            case 18: return 'Den avslutades för att sladden drogs ur.'; 
            
            case 0:  
            case 1:  
            case 2:  
            case 3:  
            case 6:  
            case 10: 
            case 11: 
            case 12:  
            case 13:  
            case 14:  
            case 15:  
            case 16: 
            case 17:  
            case 20: 
            case 21: return 'Den avslutades tyvärr pga. ett fel.'; 
            default: return 'unsupported state';
          }
    },
    status: function (value) {

        switch (value) {
            case -2: return 'STATUS_WAITING_FOR_ACTIVATION'; 
            case -1: return 'STATUS_IN_QUEUE'; 
            case 0: return 'STATUS_ACTIVE'; 
            case 1: return 'STATUS_ENDED_BY_USER'; 
            case 2: return 'STATUS_ENDED_BY_OUTLET'; 
            case 3: return 'STATUS_COMITTED'; 
            default: return 'unsupported state';
          }

    },

};


export default HelperService;